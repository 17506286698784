<template lang="pug">
  div
    TheHeader
    v-layout.auth_social(align-center, justify-center, column, fill-height)
      section
        .auth_social__title {{ $t('messages.error.undefined') }}
        .errors(v-if="errors.length")
          p(v-for="error in errors") {{ error.title }}
        //- v-layout(column, align-start)
        //-   router-link.main_link(to="") {{ $t('actions.contactUs') }}
        //-     SvgArrowRight.main_link_img
</template>

<script>
import SvgArrowRight from '@/components/svg/SvgArrowRight';
import TheHeader from '@/components/TheHeader';

export default {
  components: { SvgArrowRight, TheHeader },
  computed: {
    errors() {
      return this.$store.state.user.errorMessages;
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .auth_social {
    max-width: 550px;
    min-height: 100vh;
    padding: 0 10px;
    margin: auto;
    text-align: left;

    section {
      position: relative;
      padding-left: 80px;
      width: 100%;
    }

    &__title,
    p {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 52px;
      line-height: 1;
      color: $light-theme-primary-text;
      text-align: left;

      @media screen and (max-width: 400px) { font-size: 28px; }
    }

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
    }

    .main_link {
      margin-top: 15px;
      white-space: nowrap;
      &:first-of-type { margin-top: auto; }
    }
  }
</style>
